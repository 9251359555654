<template>
  <div class="main">
    <div class="modal">
      <div class="modal-left">
        <h2 class="title">Регистрация</h2>
        <div class="block-close">
          <svg
            width="16px"
            height="16px"
            viewBox="0 0 16 16"
            fill="#fff"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L9.06066 8L12.5303 4.53033C12.8232 4.23744 12.8232 3.76256 12.5303 3.46967C12.2374 3.17678 11.7626 3.17678 11.4697 3.46967L8 6.93934L4.53033 3.46967C4.23744 3.17678 3.76256 3.17678 3.46967 3.46967C3.17678 3.76256 3.17678 4.23744 3.46967 4.53033L6.93934 8L3.46967 11.4697C3.17678 11.7626 3.17678 12.2374 3.46967 12.5303C3.76256 12.8232 4.23744 12.8232 4.53033 12.5303L8 9.06066L11.4697 12.5303Z"
              fill="#fff"
            ></path>
          </svg>
        </div>
      </div>
      <div class="registration-form modal-right">
        <form
          action="#"
          id="registrationForm"
          :class="{'form_disabled': isRequestSending}"
          @submit.prevent="submitForm"
        >
          <div class="form-group" style="width: 100%">
            <input type="tel" id="phone" placeholder="Номер телефона" required v-model="phone" />
            <div class="iconInput">
              <svg
                data-v-b8ead291=""
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="512"
                height="512"
                aria-hidden="true"
                class="absolute top-17 left-20 fill-[#727479] wh-19"
              >
                <path
                  d="M22 16.92v3a2.06 2.06 0 0 1-2.18 2 19.74 19.74 0 0 1-8.63-2.93A19.35 19.35 0 0 1 3 10.63 19.74 19.74 0 0 1 .07 2.18 2.06 2.06 0 0 1 2.06 0h3A2.06 2.06 0 0 1 7.11 1.57a12.73 12.73 0 0 0 .7 2.72 2.05 2.05 0 0 1-.46 2.18l-1.27 1.27a16.05 16.05 0 0 0 7.12 7.12l1.27-1.27a2.06 2.06 0 0 1 2.18-.46 12.73 12.73 0 0 0 2.72.7A2.06 2.06 0 0 1 22 16.92z"
                ></path>
              </svg>
            </div>
          </div>

          <div class="form-group">
            <input type="text" id="password" placeholder="Пароль" required v-model="password" />
            <div class="iconInput">
              <svg
                data-v-b8ead291=""
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 512 512"
                xml:space="preserve"
                width="512"
                height="512"
                aria-hidden="true"
                class="absolute top-17 left-20 fill-[#727479] wh-19"
              >
                  <path
                    d="M405.333,179.712v-30.379C405.333,66.859,338.475,0,256,0S106.667,66.859,106.667,149.333v30.379   c-38.826,16.945-63.944,55.259-64,97.621v128C42.737,464.214,90.452,511.93,149.333,512h213.333   c58.881-0.07,106.596-47.786,106.667-106.667v-128C469.278,234.971,444.159,196.657,405.333,179.712z M277.333,362.667   c0,11.782-9.551,21.333-21.333,21.333c-11.782,0-21.333-9.551-21.333-21.333V320c0-11.782,9.551-21.333,21.333-21.333   c11.782,0,21.333,9.551,21.333,21.333V362.667z M362.667,170.667H149.333v-21.333c0-58.91,47.756-106.667,106.667-106.667   s106.667,47.756,106.667,106.667V170.667z"
                  ></path>
                </svg>
            </div>
          </div>
          <div class="form-group">
            <select name="currency" id="currency" v-model="currency">
              <option value="RUB" selected>Русский рубль (RUB)</option>
              <option value="UAH">Украинский гривна (UAH)</option>
            </select>
            <div class="iconInput iconCurrency">
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 425.02 511.54"
                class="absolute top-1/2 -translate-y-1/2 left-16 fill-[#727479] wh-16"
              >
                <path
                  d="m266.21,320c211.82-7.53,211.67-312.53,0-320h-85.33C116.17,0,63.54,52.65,63.54,117.33v138.67H31.54c-42.07.73-42.05,63.3,0,64h32v42.67H31.54c-42.07.73-42.05,63.3,0,64h32v53.33c.73,42.07,63.3,42.05,64,0v-53.33h138.67c42.07-.73,42.05-63.3,0-64H127.54v-42.67h138.67ZM127.54,117.33c0-29.42,23.94-53.33,53.33-53.33h85.33c126.61,3.33,126.53,188.71,0,192H127.54V117.33Z"
                ></path>
              </svg>
            </div>
          </div>
          <div class="form-row">
            <button class="promoCheck">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    viewBox="0 0 256 64"
                    class="wh-10 fill-white"
                  >
                    <path
                      d="m32,0C14.33,0,0,14.33,0,32s14.33,32,32,32h192c17.67,0,32-14.33,32-32S241.67,0,224,0H32Z"
                    ></path>
                  </svg>
                </span>
              <p>Промокод</p>
            </button>
            <input type="text" id="promo" v-model="promocode" />
          </div>
          <div class="form-check">
            <label class="perm-check">
              <input type="checkbox" id="check" checked required />
              <p>
                Я подтверждаю, что ознакомлен и соглашаюсь с
                <a href="#">условиями пользовательского соглашения</a>
              </p>
            </label>
          </div>
          <button type="submit" class="submit-btn">Зарегистрироваться</button>
        </form>
        <div class="sign-in">
          <p>Уже есть аккаунт? <a href="#">Войти</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      phone: '+7',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'TOR',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
